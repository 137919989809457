import { dataConfig } from "../../helper/Config";
import { SentryApi } from "../../helper/Sentry";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";


export class MaliShop extends Brands {
    
    public modeTest: boolean = false;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 

    public getArea = (): HTMLElement | null | undefined => {
        const elementParent = document.getElementsByTagName('safe-sticky');
        if (elementParent && elementParent.length > 0) {
            const elementBefore = document.getElementsByClassName('product-info__variant-picker');
            const element = document.createElement('div');
            element.id = 'spf-root';
            elementParent[0]?.insertBefore(element, elementBefore[0]);
            return document.getElementById("spf-root");
        }
        SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: No se encontro el elemento necesario para renderizar el widget`));
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenElements = () => {
        let elements =[];
        elements.push(document.querySelector('div.aco-box animate__none'));
        elements.push(document.querySelector('div.product-info__variant-picker'));
        elements.push(document.querySelector('div.product-info__quantity-selector'));
        elements.push(document.querySelector('div.product-info__buy-buttons'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public showElement = () => {
        let elements =[];
        elements.push(document.querySelector('div.aco-box animate__none'));
        elements.push(document.querySelector('div.product-info__variant-picker'));
        elements.push(document.querySelector('div.product-info__quantity-selector'));
        elements.push(document.querySelector('div.product-info__buy-buttons'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('products') > -1){
            return true;
        }
        return false;
    }

    /**
     * Takes the product id from inputs with name 'product-id' and return it
     * @returns {string} the product id.
     */
    public getProductId = (): string => {
        const scriptElement = document.querySelector("#price-preview");
        const dataId = scriptElement?.getAttribute("data-id");
        return dataId ?? "";
    }
}