import { ApiResponse, HttpMethods, makeRequest } from "./ApiService";
import { Product, ProductMinified } from "../models/ProductModel";
import { Data } from "../helper/Data";
import { dataConfig } from "../helper/Config";
import { SentryApi } from "../helper/Sentry";

export class ProductService {

    /** This function makes a request to retrieve a product and handles the response accordingly.
     * @return {Promise<Product | null>} The retrieved product or null if an error occurred
     */
    public static async getProduct(): Promise<Product | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/products/get?productId=${Data.getProductId()}&hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error?.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as Product;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: ProductService::getProduct fail: ${error.message}`));
            return null;
        }
    }

    /**
     * This function makes a request to retrieve a product Image and handles the response accordingly.
     * @return {Promise<ProductMinified | null>} The retrieved product or null if an error occurred
     */
    public static async getProductImage(): Promise<ProductMinified | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/products/getMinified?productId=${Data.getProductId()}&hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error?.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as ProductMinified;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: ProductService::getProductImage fail: ${error.message}`));
            return null;
        }
    }

    /** Retrieves related products based on the specified category ID and size name.
     * @param {string} categoryId - The ID of the category for which related products are being retrieved.
     * @param {string} sizeName - The name of the size for which related products are being retrieved.
     * @return {Promise<Product[] | null>} A promise that resolves with an array of related products, or null if an error occurs.
     */
    public static async getProductRelated(categoryId: string, sizeName: string, upc: string, TN: string): Promise<Product[] | null> {
        try {
            const response = await makeRequest(HttpMethods.GET, `/products/related?categoryId=${categoryId}&upc=${upc}&TN=${TN}&sizeName=${encodeURIComponent(sizeName.replace(/ /g, '+'))}&hostId=${Data.getHostId()}`);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error?.message);
            }
            if (!response.data || 0 === Object.keys(response.data).length) {
                SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: Product related fail: ${response.error?.message || 'No response data'}`));
                return null;
            }
            const ProductData = response.data as Product[];
            return ProductData;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: ProductService::getProductRelated fail: ${error.message}`));
            return null;
        }
    }
} 
