import {
    Flex,
    Text,
    Button,
    useToast,
  } from "@chakra-ui/react";
import { useEffect } from "react";
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from "../../providers/AddressProvider";
import { useModaladdress2 } from "../../hooks/useModaladdress2";
import { AddressModel } from "../../models/AddressModel";
import { svgReturn } from "../../helper/Svg";

export const Address = () => {

  const widget = useWidget();
  const addr = useAddress();
  const toast = useToast();

  // If the widget is null, return nothing.
  if (widget == null) return <></>;

  const {
    buttonSaveActive,
    country,
    locality,
    formatAddress,
    latitude,
    longitude,
    additionalInfo,
    iniMap,
    handlerAdditionalInformation,
  } = useModaladdress2();

  const styleInputMap = {
    border: "2px solid rgb(217, 191, 237)",
    borderRadius: "10px",
    width: "100%",
    height: "35px",
    margin: "10px auto",
    paddingLeft: "15px",
}

  useEffect(() => {
    iniMap();
  }, []);

  /** A function that handles saving an address.
   * @return {Promise<void>} a promise with the result of saving the address
   */
  const handlerSaveAddress = async (): Promise<void> => {

    //widget.handlerSteps('data-customer');
    //return;
     
    // If address is empty, return error.
    if (formatAddress == "" || additionalInfo == "") {
        toast({
          title: "Disculpe.",
          description: "Complete los datos de la dirección para continuar.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
    }

    // Create a new address.
     const newAddress: AddressModel = {
        id: 0,
        customer_id: "",
        country_name: country,
        city_name: locality,
        address: formatAddress,
        additional_info: additionalInfo,
        latitud: latitude,
        longitud: longitude,
        place_Id: "--",
        commission: 0,
    };

   // Call the function to save the address
    const result = await addr?.changeAddress(newAddress);

    // If the result is successful, go to the next step
    if (result?.code === 200) {
        widget.handlerSteps('data-customer');
    }

    // Show the result.
    toast({
      title: result?.title,
      description: result?.message,
      status: result?.type,
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Flex
    direction="column"
    //alignItems="center"
    bg="white"
    gap="0.4rem"
    p={5}
    >
       <Flex
        onClick={() => widget.handlerSteps("product")}
        justifyContent="left"
        px={5}
        mt={3}
        bg="#fcf6ff"
        style={{ cursor: "pointer" }}
      >
        {svgReturn}
        <Text textAlign="end" color="purple.700">ir atrás</Text>
      </Flex>
      <Text fontWeight="bold" fontSize="26px" textAlign="center">
        Dime dónde estás
      </Text>
      <Text textAlign="center" w="80%" margin="auto">
        Para validar disponibilidad del servicio en tu zona
      </Text>
      <input
        type="text"
        id="place_input"
        placeholder="Dirección o punto de referencia"
        style={styleInputMap}
      />
      <div id="map" style={{ width: "100%", minHeight: "135px" }}></div>
      <input
        id="additional-info-dato"
        type="text"
        name="aditionalInfo"
        placeholder="#casa o apartamento"
        style={styleInputMap}
        onChange={handlerAdditionalInformation}
      />
      <Button
        onClick={handlerSaveAddress}
        w="100%"
        bg="purple.700"
        color="white"
        isDisabled={!buttonSaveActive}
        isLoading={addr?.isLoading}
        loadingText="Espere un momento"
        _hover={{ bg: "purple.400" }}
      >
        Continuar
      </Button>
  </Flex>
  )
}
