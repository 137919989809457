import { useState } from "react";
import ReactFlagsSelect from "react-flags-select"; //https://github.com/ekwonye-richard/react-flags-select/blob/master/README.md
//import 'react-flags-select/css/react-flags-select.css';
import {
  Flex,
  Box,
  Input,
  Button,
  HStack,
  PinInput,
  PinInputField,
  useToast,
} from "@chakra-ui/react";
import { usePhoneValidate } from "../../hooks/usePhoneValidate";
import { useWidget } from "../../providers/WidgetProvider";
import { callingCodes } from "../../helper/callingCodeImages";
import { svgWhatsapp } from "../../helper/Svg";
import { useAddress } from "../../providers/AddressProvider";

export const PhoneVerified = () => {
  const widget = useWidget();
  const toast = useToast();
  const addr = useAddress();
  const callingCode: any = callingCodes();

  // Return the country code based on the country name.
  const getCodeContry = (contryName: string) => {
    for (let key in callingCode) {
      if (callingCode[key].primary === contryName) {
        return key;
      }
    }
    console.log("any");
  };

  // States country code selected.
  const [selected, setSelected] = useState(
    getCodeContry(addr?.address?.country_name as string) || ""
  );

  // If the widget is null, return nothing.
  if (widget == null) return <></>;
  
  // Desestructure the variables and functions from usePhoneValidate.
  const {
    waitCodeOTP,
    btnText,
    isLoadingSending,
    handlerCodePhone,
    handlerPhone,
    handlerInputPin,
    sendCustomerPhone,
    sendCodeOtp,
    setBtnContinuarPhone,
  } = usePhoneValidate();

  // List of contry codes.
  const listCountry = Object.keys(callingCode);

  /** A function that handles the validation of the OTP button.
   * @return {Promise<void>} It returns a promise with no value.
   */
  const handlerButtonOtpValidate = async (): Promise<void> => {
    const pinField = pinFieldsValidation();
    if (!pinField) {
      toast({
        title: "Faltan datos.",
        description: "Completa el pin de 4 dígitos que enviamos a tu Whatsapp.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const isValid = await sendCodeOtp();
    if (!isValid) {
      toast({
        title: "Hemos tenido problemas para validar tu código.",
        description: "Por favor, verifica tu whatsapp e intenta de nuevo.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setBtnContinuarPhone(false);
      return;
    }
    const dataAddress = await addr?.getDefaultAddress();
    if (!dataAddress || dataAddress?.additional_info == "anonymous") {
      widget.handlerSteps("address");
    } else {
      widget.handlerSteps("data-customer");
    }
  };

  /** A function to validate pin fields.
   * @return {boolean} true if all pin fields are filled, false otherwise
   */
  const pinFieldsValidation = (): boolean => {
    // Se coloca por que el copiar y pegar no funciona en MacOS.
    const pinfieldOne = (
      document.getElementsByName("pinOne")[0] as HTMLInputElement
    )?.value;
    const pinfieldTwo = (
      document.getElementsByName("pinTwo")[0] as HTMLInputElement
    )?.value;
    const pinfieldThree = (
      document.getElementsByName("pinThree")[0] as HTMLInputElement
    )?.value;
    const pinfieldFour = (
      document.getElementsByName("pinFour")[0] as HTMLInputElement
    )?.value;
    return  pinfieldOne.length === 1 && 
            pinfieldTwo.length === 1 &&
            pinfieldThree.length === 1 &&
            pinfieldFour.length === 1;
  }

  
  /** Function for handling the customer phone button click.
   * @return {Promise<void>} 
   */
  const handlerButtonCustomerPhone = async (): Promise<void> => {
    onSelectFlag(selected);
    const phoneCode = `+${callingCode[selected]?.secondary}`;
    const response = await sendCustomerPhone(phoneCode);
    toast({
      title: response
        ? response[0]
        : "Hemos tenido problemas para enviar tu código.",
      description: response
        ? response[1]
        : "Por favor, verifica tu código de país y número.",
      status: response && response[2] ? "success" : "error",
      duration: 4000,
      isClosable: true,
    });
    document
      .getElementById("pin-trycloud")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  /** A function that handles the selection of a country flag.
   * @param {string} countryCode - the country code to be selected
   * @return {void} 
   */
  const onSelectFlag = (countryCode: string): void => {
    setSelected(countryCode);
    const phoneCode = `+${callingCode[countryCode]?.secondary}`;
    handlerCodePhone(phoneCode);
  };

  return (
    <Flex direction="column" px={5}>
      <Box pt="10px" fontSize="20px" color="purple.700" fontWeight="bold">
        Verifica tu teléfono
      </Box>
      <Flex
        m="15px"
        p={5}
        border="1px"
        borderRadius="5px"
        borderColor="gray.200"
        direction="column"
      >
        <Flex>
          <Box fontSize="15px">{`Ingresa tu número de `}</Box>
          <Box mx="5px">{svgWhatsapp} </Box>
          <Box fontSize="15px"> WhatsApp</Box>
        </Flex>
        <Flex mt="25px" columnGap={2}>
          <ReactFlagsSelect
            searchable={true}
            selected={selected}
            placeholder="Código de país"
            searchPlaceholder="Selecciona el país"
            countries={listCountry}
            customLabels={callingCode}
            showSelectedLabel={false}
            showSecondarySelectedLabel={true}
            showOptionLabel={true}
            showSecondaryOptionLabel={true}
            selectedSize={14}
            optionsSize={14}
            disabled={false}
            onSelect={onSelectFlag}
            fullWidth={false}
          />
          <Input
            placeholder="____-_______"
            size="sm"
            bg="#F7E6FF"
            onChange={handlerPhone}
          />
        </Flex>
        <Flex justifyContent="right">
          <Box
            as={Button}
            mt="10px"
            textAlign="right"
            color="blue.500"
            w="135px"
            onClick={handlerButtonCustomerPhone}
            isLoading={isLoadingSending}
          >
            {btnText}
          </Box>
        </Flex>
        <HStack
          id="pin-trycloud"
          mx="auto"
          mt="15px"
          display={waitCodeOTP ? "flex" : "none"}
        >
          <PinInput otp>
            <PinInputField
              bg="#F7E6FF"
              color="purple.700"
              fontSize="15px"
              fontWeight="bold"
              name="pinOne"
              id="pinOne"
              onChange={handlerInputPin}
            />
            <PinInputField
              bg="#F7E6FF"
              color="purple.700"
              fontSize="15px"
              fontWeight="bold"
              name="pinTwo"
              id="pinTwo"
              onChange={handlerInputPin}
            />
            <PinInputField
              bg="#F7E6FF"
              color="purple.700"
              fontSize="15px"
              fontWeight="bold"
              name="pinThree"
              id="pinThree"
              onChange={handlerInputPin}
            />
            <PinInputField
              bg="#F7E6FF"
              color="purple.700"
              fontSize="15px"
              fontWeight="bold"
              name="pinFour"
              id="pinFour"
              onChange={handlerInputPin}
            />
          </PinInput>
        </HStack>
        <Box
          mt="15px"
          fontSize="15px"
          fontWeight="bold"
          color="purple.700"
          textAlign="justify"
          display={waitCodeOTP ? "block" : "none"}
        >
          Ingresa el código que recibirás en pocos segundos
        </Box>
      </Flex>
      <Box fontSize="12px" color="gray.500" textAlign="justify">
        Al hacer clik en continuar, autorizas el tratamiento de tus datos
        personales según nuestra política de privacidad y aceptas los términos y
        condiciones
      </Box>
      <Button
        w="90%"
        mt={5}
        mx="auto"
        rounded="5px"
        bg="purple.700"
        color="white"
        _hover={{ bg: "purple.400" }}
        loadingText="Procesando"
        onClick={handlerButtonOtpValidate}
      >
        Finalizar
      </Button>
    </Flex>
  );
};
