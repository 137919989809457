import { dataConfig } from '../helper/Config';
import { Data } from '../helper/Data';
import { SentryApi } from '../helper/Sentry';
import { CustomerModel } from '../models/CustomerModel';
import { OTPValidate } from '../models/OTPValidate';
import { ApiResponse, HttpMethods, makeRequest } from './ApiService';

export class CustomerService {

    /** Update a customer in the voiew of confirmation data Customer.
     * @param {CustomerModel} customer - the customer object to update
     * @return {Promise<CustomerModel | null>} the updated customer object, or null if there is an error
     */
    public static async updateCustomer(customer: CustomerModel): Promise<CustomerModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, '/customer/update', customer);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return null;
            }
            return response.data as CustomerModel;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: CustomerService::updateCustomer fail: ${error.message}`));
            return null;
        }
    }

    /** Retrieves customer data from the server.
     * @return {Promise<CustomerModel | null>} The retrieved customer data, or null if there was an error.
     */
    public static async getCustomer(): Promise<CustomerModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, '/customer/get');
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                console.log('CustomerService::getCustomer fail');
                return null;
            }
            return response.data as CustomerModel;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: CustomerService::getCustomer fail: ${error.message}`));
            return null;
        }
    }

    /** Validate the phone number for a customer.
     * @param {string} phone - the new phone number to be updated
     * @return {Promise<boolean>} true if the phone number is updated successfully, false otherwise
     */
    public static async validatePhone(phone: string): Promise<boolean> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, `/customer/validatePhoneNumber?hostId=${Data.getHostId()}`, { phoneNumber: phone });
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response.data) {
                return false;
            }
            return response.data as boolean;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: CustomerService::updatePhone fail: ${error.message}`));
            return false;
        }
    }

    /** Validates the OTP code for a given phone number.
     * @param {string} otpCode - The OTP code to validate.
     * @param {string} phone - The phone number associated with the OTP code.
     * @return {Promise<OTPValidate | false>} A boolean indicating whether the OTP code is valid or not.
     */
    public static async validateOtp(otpCode: string, phone: string): Promise<OTPValidate | false> {
        try {
            const params = { codeOTP: otpCode, phoneNumber: phone };
            const response = await makeRequest(HttpMethods.POST, `/customer/validateOtp?hostId=${Data.getHostId()}`, params);
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                throw new Error(response.error.message);
            }
            if (!response?.data) {
                return false;
            }
            return response.data as OTPValidate;
        } catch (error: any) {
            SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: CustomerService::validateOtp fail: ${error.message}`));
            return false;
        }
    }
}