import { dataConfig } from '../../helper/Config';
import { Data } from '../../helper/Data';
import { SentryApi } from '../../helper/Sentry';
import { AddressModel } from '../../models/AddressModel';
import { ApiResponse, HttpMethods, makeRequest } from '../ApiService';
import * as Sentry from "@sentry/react";

export class AddressService {

    /** Retrieves the default address.
     * @return {Promise<any>} The default address
     */
    public static async getAddressDefault(): Promise<AddressModel | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/address/getDefaultAddr?hostId=${Data.getHostId()}`);
            // If there is an error, throw an error.
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                const errorMessage = `${dataConfig.metricsPrefix}: AddressService::getAddressDefault fail: ${response.error?.message}`;
                Sentry.captureException(errorMessage);
                return null;   
            }
            if (!response.data) {
                const errorMessage = `${dataConfig.metricsPrefix}: AddressService::getAddressDefault fail: ${response.error?.message || 'No response data'}`;
                SentryApi.captureException(new Error(errorMessage));
                return null;    
            }
            return response.data as AddressModel;
        } catch (error:any) {
            const errorMessage = `${dataConfig.metricsPrefix}: AddressService::getAddressDefault fail: ${error.message}`
            SentryApi.captureException(new Error(errorMessage));
            return null;       
        }
    }

    /** Nueva dirección para el cliente.
     * @param {AddressModel} address - the address to be added.
     * @returns 
     */
    public static async addAddress(address: AddressModel): Promise<any> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, `/address/add`, address)
            if (response.error && response.error.message &&  0 < response.error.message.length) {
                const errorMessage = `${dataConfig.metricsPrefix}: AddressService::addAddress fail: ${response.error?.message}`;
                Sentry.captureException(errorMessage);
                return 'Tuvimos problemas para retornar tu dirección.';   
            }
            if (!response.data){
                const errorMessage = `${dataConfig.metricsPrefix}: AddressService::addAddress fail: ${response.error?.message || 'No response data'}`;
                SentryApi.captureException(new Error(errorMessage));
                return 'Tuvimos problemas creando tu dirección.';       
            }
            return response.data;
        } catch (error:any) {
            const errorMessage = `${dataConfig.metricsPrefix}: AddressService::addAddress fail: ${error.message}`;
            SentryApi.captureException(new Error(errorMessage));
            return 'Tuvimos problemas creando tu dirección. Por favor, intenta de nuevo.';
        }
    }
}
