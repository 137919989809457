import { useContext, useEffect, useState } from "react";
import {
  Product,
  VariationsSize,
  VariationsColor,
  productOutStock,
} from "../models/ProductModel";
import { ProductService } from "../services/ProductService";
import { useShoppingCart } from "./ShoppingCartProvider";
import { ShoppingCartModel } from "../models/ShoppingCartModel";
import { ProductContexType, ProductContext } from "../context/ProductContext";
import { useWidget } from "./WidgetProvider";
import { AuthService } from "../services/AuthService";

export const ProductProvider = ({children, }: { children: React.JSX.Element;}): React.JSX.Element => {
  const [product, setProduct] = useState<Product | null>(null); //Producto que se esta viendo.
  const [visitedProduct, setVisitedProduct] = useState<Product | null>(null); //Producto que se visitó.
  const [productOutStock, setProductOutStock] = useState<productOutStock | null>(null); //variación del producto OutStock.
  const [showReturn, setShowReturn] = useState<boolean>(false); //Indica si se muestra barra de retorno.
  const [productRelated, setProductRelated] = useState<Product[] | null>([]); //Producto sugerido
  const [colors, setColors] = useState<VariationsColor[] | []>([]); //Colores del producto
  const [sizes, setSizes] = useState<VariationsSize[] | []>([]); //Tallas del producto.
  const [loading, setLoading] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  // Hooks
  const shopCart = useShoppingCart();
  const widget = useWidget();
  
  const cart: ShoppingCartModel[] | [] = shopCart?.cart ?? [];

  /** A function that fetches a product and updates the state accordingly.
   * @param {ShoppingCartModel[] | undefined} newcart - an optional array of ShoppingCartModel representing the new cart
   * @return {void} 
   */
  const fetchProduct = async (newcart: ShoppingCartModel[] | undefined): Promise<void> => {
    try {
      if (!widget?.isOn || !AuthService.validateSession()) return;
      const response: Product | null = await ProductService.getProduct(); 
      setVisitedProduct(response);
      productToShow(response, newcart ?? []);
      setInitialized(true);
    } catch (error) {
      console.log("fetchProducts: error: ", error);
    }
  };

  /** Fetch related products based on the size name.
   * @param {string} sizeName - the name of the size
   * @return {void} 
   */
  const fetchProductsRelated = async (sizeName: string, TN: string, upc: string): Promise<void> => {
    if (product == null) return;
    const response: Product[] | null = await ProductService.getProductRelated(product.path, sizeName, upc, TN);
    setProductRelated(response);
    const firstProduct = response?.length ? response[0] : null;
    productToShow(firstProduct, shopCart?.cart ?? []);
  }

   const getProduct = async function() {
    try {
      setLoading(true);
      const newcart = await shopCart?.getShoppingCart();
      fetchProduct(newcart);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!widget?.isOn || !AuthService.validateSession()) return;
    getProduct();
  }, [widget?.isOn]);

  const productToShow = ( productSelected: Product | null, cartNew: ShoppingCartModel[] | [] ): void => {
    if (productSelected == null || productSelected == undefined) return;
    setProduct(productSelected);
    setImage(productSelected.image);
    const variariationColor = productSelected.variationsColor as VariationsColor[] | [];
    let variationSize = variariationColor[0]?.variationsSize as VariationsSize[] | [];
    setColors(variariationColor);
    const newSize = selectVariation(variationSize, cartNew );
    setSizes(newSize);
  };

  /**
   * Selects variations based on sizes and shopping cart.
   * @param {VariationsSize[] | []} sizes - array of variations sizes
   * @param {ShoppingCartModel[] | []} cart - array of shopping cart items
   * @return {VariationsSize[] | []} array of selected variations sizes
   */
  const selectVariation = ( sizes: VariationsSize[] | [], cart: ShoppingCartModel[] | [] ): VariationsSize[] | [] => {
    const newSize: VariationsSize[] = [];
    sizes.map((element) => {
      element.isSelected = false;
      if (cart.length > 0) {
        cart.map((index) => {
          if ( index.upc == element.Upc ) {
            element.isSelected = true;
          }
        });
      }
      newSize.push({ ...element });
    });
    return newSize;
  };

  const btnNext = (): void => {
    if (product == null) return;
    if (productRelated == null || productRelated?.length == 0) return;
    if (productOutStock !== null)  setShowReturn(true); 
    if ( product?.TNP === productRelated[productRelated.length - 1].TNP ) return;
    setImage('');
    setLoading(true);
    const productSelected = productRelated[productRelated.indexOf(product) + 1];
    productToShow(productSelected, shopCart?.cart ?? []);
    setLoading(false);
  };

  const btnPrev = (): void => {
    if (product == null) return;
    if (productRelated == null || productRelated?.length == 0) return;
    if (productOutStock !== null)  setShowReturn(true); 
    if (product?.TNP === productRelated[0].TNP) return;
    const productSelected = productRelated[productRelated.indexOf(product) - 1];
    productToShow(productSelected, shopCart?.cart ?? []);
  };

  const handlerColor = (color: VariationsColor) => {
    console.log(color.colorName);
    setSizes(color.variationsSize);
    console.log(color.variationsSize);
    setImage(color.image);
  };

  const delVariation = async(TN:string): Promise<void> => {
    await shopCart?.delShoppingCart(TN);
  }

  const handlerReturn = (): void => {
    productToShow(visitedProduct, shopCart?.cart ?? []);
    setProductOutStock(null);
    setProductRelated([]);
  };

  const prod: ProductContexType = {
    product,
    visitedProduct,
    colors,
    sizes,
    productOutStock,
    showReturn,
    productRelated,
    loading,
    initialized,
    image,
    cart,
    setLoading,
    setShowReturn,
    setProductOutStock,
    btnNext,
    btnPrev,
    handlerColor,
    handlerReturn,
    delVariation,
    fetchProduct,
    fetchProductsRelated,
  };

  return (
    <ProductContext.Provider value={prod}>{children}</ProductContext.Provider>
  );
};

export const useProduct = () => {
  const prod: ProductContexType | null = useContext(ProductContext);
  return prod;
};
