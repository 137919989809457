import { dataConfig } from '../../helper/Config';
import { Data } from '../../helper/Data';
import { SentryApi } from '../../helper/Sentry';
import { AnonimosAddressModel } from '../../models/AnonimousAddressModel';
import { CitiesModel } from '../../models/CitiesModel';
import { ApiResponse, HttpMethods, makeRequest } from '../ApiService';

export class CityService {

    /** Add address by city ID
     * @return {Promise<boolean|string>} A boolean or string indicating success or failure
     */
    public static async addAddress(): Promise<AnonimosAddressModel | false> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, `/cityController/addAddressByCity`, { hostId: Data.getHostId() });
            if (response.error && response.error.message && 0 < response.error.message.length) {
                const errorMessage = `${dataConfig.metricsPrefix}: CityService::addAddress fail: ${response.error?.message}`;
                SentryApi.captureException(new Error(errorMessage));
                return false;
            }
            if (!response.data || 0 === Object.keys(response.data).length) {
                const errorMessage = `${dataConfig.metricsPrefix}: CityService::addAddress fail: ${response.error?.message || 'No response data'}`;
                SentryApi.captureException(new Error(errorMessage));
                return false;
            }
            return response.data as AnonimosAddressModel;

        } catch (error:any) {
            const errorMessage = `${dataConfig.metricsPrefix}: CityService::add fail: ${error.message}`;
            SentryApi.captureException(new Error(errorMessage));
            return false;
        }
    }

    /** Get cities from the server.
     * @return {Promise<CitiesModel[] | null>} The cities data if successful, otherwise null
     */
    public static async get(): Promise<CitiesModel[] | null> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.GET, `/city/getCities?hostId=${Data.getHostId()}`);
            if (response.error && response.error.message && 0 < response.error.message.length) {
                const errorMessage = `${dataConfig.metricsPrefix}: CityService::get fail: ${response.error?.message}`;
                SentryApi.captureException(new Error(errorMessage));
                return null;
            }
            if (!response.data || 0 === Object.keys(response.data).length) {
                const errorMessage = `${dataConfig.metricsPrefix}: CityService::get fail: ${response.error?.message || 'No response data'}`;
                SentryApi.captureException(new Error(errorMessage));
                return null;
            }
            return response.data as CitiesModel[];
        } catch (error:any) {
            const errorMessage = `${dataConfig.metricsPrefix}: CityService::get fail: ${error.message}`;
            SentryApi.captureException(new Error(errorMessage));
            return null;
        }
    }

    /** Change city address
     * @param {number} cityId - The ID of the city
     * @return {Promise<boolean|string>} A boolean or string indicating success or failure
     * */
    public static async changeCityAddress(cityId: string): Promise<AnonimosAddressModel | false> {
        try {
            const response: ApiResponse = await makeRequest(HttpMethods.POST, `/city/changeCityAddress`, { cityId, hostId: Data.getHostId() });
            if (response.error && response.error.message && 0 < response.error.message.length) {
                const errorMessage = `${dataConfig.metricsPrefix}: CityService::changeCityAddress fail: ${response.error?.message}`;
                SentryApi.captureException(new Error(errorMessage));
                return false;
            }
            if (!response.data || 0 === Object.keys(response.data).length) {
                const errorMessage = `${dataConfig.metricsPrefix}: CityService::changeCityAddress fail: ${response.error?.message || 'No response data'}`;
                SentryApi.captureException(new Error(errorMessage));
                return false;
            }
            return response.data as AnonimosAddressModel;
        } catch (error:any) {
            const errorMessage = `${dataConfig.metricsPrefix}: CityService::changeCityAddress fail: ${error.message}`;
            SentryApi.captureException(new Error(errorMessage));
            return false;
        }
    }
}
