import { getInstance } from "./BrandFactory";

export class Data{
    
    /**
     * Gets the host id.
     * @returns {string} The host id.
     */
    public static getHostId = (): string => {
        const scriptElement = document.getElementById("trycloud-app");
        const dataId = scriptElement?.getAttribute("data-id");
        return dataId ?? "";
    };

    /**
     * Gets el id del producto en la página.
     * @returns {string} El id del producto en la página.
     */
    public static getProductId = (): string => {
        const brand = getInstance();
        return brand.getProductId();
    }

    /**
     * Gets the platform.
     * @returns {string} The platform.
     */
    public static getPlatform = (): string => {
        const scriptElement = document.getElementById("trycloud-app");
        const dataPlatform = scriptElement?.getAttribute("data-plat");
        return dataPlatform ?? "";
    }

    /**
     * Verifies if the environment is development.
     * @returns {boolean} Whether the environment is development or not.
     */
    public static isDevelopmentEnv = (): boolean =>  document.location.hostname.includes('localhost') || document.location.hostname.includes('trucloud-test.s3.us-east-2.amazonaws.com') || document.location.hostname.includes('trycloud.s3.us-east-2.amazonaws');
}