import { useState } from "react";
import { FormControl, Input, Box, Button, Flex, useToast } from "@chakra-ui/react";
import { useCustomerForm } from "../../hooks/useCustomerForm";
import { useOrder } from "../../hooks/useOrder";

interface props {
  handlerSteps: (
    nextStep: "product" | "phone" | "data-customer" | "finish"
  ) => void;
}

export const CustomerForm = (prop: props) => {
  const [isLoading, setisLoading] = useState(false);
  const { handlerSteps } = prop;
  const {customerName, email, documentId, btnDisabledCustomerFrom, handlerName, handlerEmail, handlerDocumentId, sendCustomerData} = useCustomerForm();
  const {createOrder, getOrder} = useOrder();
  const toast = useToast();
  
  /** A function that handles the next step in the process.
   * @return {Promise<void>} The function does not return anything.
   */
  const nextStep = async (): Promise<void> => {
      // If address is not anonymous, it will continue with the next step.
      setisLoading(true);
      // Send customer data.
      const respCustomer = await sendCustomerData();
      // If error, return error string with error..
      if(!respCustomer) {
        toast({ title: "Hemos tenido problemas para actualizar tus datos.", description: "Por favor, intenta de nuevo.", status: "error", duration: 4000, isClosable: true })
        setisLoading(false);
        return;
      } 
      //validate order in progress.
      const order = await getOrder();
      if(order) {
        toast({ title: "Ya tienes una orden en proceso para esta cuenta.", description: "Por favor, espera a que finalice, antes de realizar una nueva.", status: "error", duration: 4000, isClosable: true })
        setisLoading(false);
        return;
      }
      // Create order.
      const respOrder = await createOrder();
      // If error, return error string with error.
      if(!respOrder) {
        toast({ title: "Hemos tenido problemas para generar tu orden.", description: "Por favor, intenta de nuevo.", status: "error", duration: 4000, isClosable: true })
        setisLoading(false);
        return;
      }  
      // If everything is ok, go to next step.
      setisLoading(false);
      handlerSteps("finish");
  } 

  return (
    <>
      <Box mt="15px" fontSize="20px" fontWeight="bold" color="purple.700">
        Completa tus datos
      </Box>
      <Flex mt="15px" justifyContent="center">
        <FormControl isRequired w="80%">
          <Input mt="10px" bg="#F7E6FF" name="name" value={customerName} placeholder="Nombre y Apellido" onChange={handlerName} />
          <Input
            mt="10px"
            bg="#F7E6FF"
            name="document-id"
            value={documentId}
            placeholder="Cédula"
            onChange={handlerDocumentId}
          />
          <Input
            mt="10px"
            bg="#F7E6FF"
            value={email}
            name="email"
            type="email"
            placeholder="Email"
            onChange={handlerEmail}
          />
        </FormControl>
      </Flex>
      <Button
        w="90%"
        mt={5}
        mx="auto"
        rounded="5px"
        bg="purple.700"
        color="white"
        _hover={{ bg: "purple.400" }}
        isLoading={isLoading}
        loadingText="Procesando"
        onClick={nextStep}
        isDisabled={btnDisabledCustomerFrom}
      >
        Confirmar
      </Button>
    </>
  );
};
