import { dataConfig } from "../../helper/Config";
import { SentryApi } from "../../helper/Sentry";
import { Brands } from "./Brands";
import { DataConfigModel } from "../../models/DataConfigModel";

export class BosiCo extends Brands {

    public modeTest: boolean = true;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        // get elements necessary to render.
        const element = document.querySelector('#productInfo');
        //const elementPriceProduct = document.querySelector('div.priceProduct');
        const elementPriceProduct = document.querySelector('div.boxButtons');
        if (!elementPriceProduct) return;
         // If Element is not null, create element trycloud.   
        if (element != null || element != null) {
            // create element trycloud.
            const trycloudArea = document.createElement("div");
            trycloudArea.style.cssText = "margin-top: -20px;";
            trycloudArea.setAttribute("id", "trycloud-area");
            trycloudArea.setAttribute("name", "trycloud-area");
            // Append element trycloud.
            element?.insertBefore(trycloudArea, elementPriceProduct.nextSibling);
            return trycloudArea;
        }
        // Send metrics error.
        SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: No se encontro el elemento necesario para renderizar el widget`));
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hiddenElements = () => {
        let elements =[]; 
        elements.push(document.querySelector('div.selectionProduct'));
        elements.push(document.querySelector('div.wrap-boxcolor'));
        elements.push(document.querySelector('div.price-comment'));
        elements.push(document.querySelector('addi-product-widget'));
        //elements.push(document.querySelector('#banco-prices'));
        //elements.push(document.querySelector('div.priceProduct.sin-iva'));
        elements.push(document.querySelector('div.boxCantidad'));
        elements.push(document.querySelector('div.boxButtons'));
        elements.push(document.querySelector('div.boxShare'));
        //elements.push(document.querySelector('div.message_morral'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "display:none";
            }
        }
    }

    public showElement = () => {
        let elements =[]; 
        elements.push(document.querySelector('div.selectionProduct'));
        elements.push(document.querySelector('div.wrap-boxcolor'));
        elements.push(document.querySelector('div.price-comment'));
        elements.push(document.querySelector('addi-product-widget'));
        //elements.push(document.querySelector('#banco-prices'));
        //elements.push(document.querySelector('div.priceProduct.sin-iva'));
        elements.push(document.querySelector('div.boxCantidad'));
        elements.push(document.querySelector('div.boxButtons'));
        elements.push(document.querySelector('div.boxShare'));
        //elements.push(document.querySelector('div.message_morral'));
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element){
                element.style.cssText = "";
            }
        }
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/p') > -1){
            return true;
        }
        return false;
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     public getProductId = (): string => {
        const productDataElement = document.getElementById('productInfo');
        // if not exist return empty.
        if (!productDataElement || !productDataElement.dataset.idproduct) return '';
        // return product id from 'data-idproduct' concat 'p'.
        return `P${productDataElement.dataset.idproduct}`;
    }
}